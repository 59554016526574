html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #fff;
}

body {
  overflow-y: scroll;
}

app-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

h1,
h2 {
  color: #d32f2f;
}

h1,
h2,
p {
  margin: 8px;
}

a {
  color: #1976d2;
}

header {
  display: block;
  max-width: 960px;
  margin: 0 auto;
  flex-shrink: 0;
  width: 100%;
}

#app-container {
  flex: 1 0 auto;
  display: block;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

footer {
  margin-top: 24px;
  flex-shrink: 0;
  height: 128px;
  background-color: #d32f2f;
  color: #fff;
  width: 100%;
}

@media (max-width: 768px) {
  footer {
    height: 256px;
  }
}

footer content {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

footer a {
  color: #fff;
}

mat-toolbar-row {
  max-width: 960px;
  margin: 0 auto;
  padding-left: 0 !important;
}

node-package {
  margin: 16px 8px;
  display: block;
  width: calc(100% - 16px) !important; /* Hack for lack of shadowdom support */
  --node-package-background-color: #fff;
  --node-package-color: #d32f2f;
  --node-package-link-color: #b71c1c;
  border: 1px solid #d32f2f;
}

.fixedwidth,
pre,
code {
  font-family: monospace;
}

.warning {
  padding: 16px;
  margin: 16px 8px;
  border-radius: 8px;
  background-color: #1976d2;
  color: #fff;
}

.warning a {
  color: #fff;
}

.notice {
  padding: 16px;
  margin: 16px 8px;
  border-radius: 8px;
  border: 1px solid #1976d2;
  color: #1976d2;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }















@import url('https://fonts.googleapis.com/css?family=Roboto');



